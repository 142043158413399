$(document).ready(function() {
  if ($('#take-test-container').length) {
    setupTestTimer();

    $('input:checkbox').on('change', function(){
      let askedQuestionAnswerOptionId = $(this).data('asked-question-answer-option-id');
      let askedQuestionAnswerOptionValue = $(this).is(":checked");

      $.ajax({
        url: $('#take-test-container').data('select-asked-question-answer-option-url'),
        type: 'POST',
        data: {
          asked_question_answer_option_id: askedQuestionAnswerOptionId,
          asked_question_answer_option_value: askedQuestionAnswerOptionValue,
        },
        error: function(response) {
          alert(response.responseText);
        }
      });
    });

    function setupTestTimer() {
      let testSeconds = $('#remaining-time').data('total-test-time-in-seconds');
      $('#remaining-time').text(formattedRemainingTime(testSeconds));
      var interval = setInterval(function(){
        testSeconds--;
        $('#remaining-time').text(formattedRemainingTime(testSeconds));
        if (testSeconds === 0){
          clearInterval(interval);
          alert('You have reached the test submission deadline. Your test answers have been submitted and you will now be redirected to the course dashboard');
          $("#finish-test-form").submit();
        }
      }, 1000);
    }

    // Format seconds to HH:MM:SS
    function formattedRemainingTime(seconds) {
      return new Date(seconds * 1000).toISOString().substring(11, 19);
    }
  }
});